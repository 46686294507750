import { createRouter, createWebHistory } from 'vue-router'
import index from '../views/index.vue'
import picc from '../views/picc.vue'
import dy from '../views/dy.vue'

const routes = [
  {
    path: '/picc',
    name: 'picc',
    component: picc
  },
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/dy',
    name: 'dy',
    component: dy
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
